import { useNavigate } from '@reach/router';
import {
  Alert,
  Checkbox,
  Container,
  FormSubmit,
  PasswordRepeatInput,
  ValidatedInput,
} from 'components';
import { PasswordInput } from 'components/PasswordInput';
import { EmailRegExp, PasswordRegExp } from 'consts';
import { Link } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import { useAnalytics } from 'modules/analytics/hooks/useAnalytics';
import {
  AuthThunks,
  FacebookAuth,
  GoogleAuth,
  OrDivider,
  RegistrationForm,
} from 'modules/authentication';
import { AuthLogo } from 'modules/navigation';
import { AppDispatch, ApplicationState } from 'modules/redux-store';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { auth, spacers } from 'styles';

const Register: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { formatMessage } = useIntl();

  const { sendContactInteraction, sendRegistrationInteraction } =
    useAnalytics();

  const { authStateChanging, error } = useSelector(
    (state: ApplicationState) => state.auth,
  );

  const methods = useForm<RegistrationForm>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const navigateTo = useNavigate();

  return (
    <Container className={auth.loginFlex}>
      <AuthLogo />

      <div css={auth.form}>
        <div css={auth.formTitle}>
          <h2 css={[spacers.bottom.medium]}>
            <FormattedMessage id="registration.title" />
          </h2>

          <p>
            <FormattedMessage id="registration.existing-user.content" />{' '}
            <Link to="/prijava">
              <FormattedMessage id="registration.existing-user.link" />
            </Link>
          </p>
        </div>

        <FacebookAuth
          text={formatMessage({ id: 'registration.facebook-authorization' })}
        />

        <GoogleAuth
          text={formatMessage({ id: 'registration.google-authorization' })}
        />

        <OrDivider />

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <ValidatedInput
              name="firstName"
              label={formatMessage({
                id: 'registration.input.first-name.label',
              })}
              placeholder={formatMessage({
                id: 'registration.input.first-name.placeholder',
              })}
              validationObject={{
                required: formatMessage({ id: 'shared.validation.required' }),
              }}
            />

            <ValidatedInput
              name="lastName"
              label={formatMessage({
                id: 'registration.input.last-name.label',
              })}
              placeholder={formatMessage({
                id: 'registration.input.last-name.placeholder',
              })}
              validationObject={{
                required: formatMessage({ id: 'shared.validation.required' }),
              }}
            />

            <ValidatedInput
              name="email"
              label={formatMessage({ id: 'shared.input.email.label' })}
              placeholder={formatMessage({
                id: 'shared.input.email.placeholder',
              })}
              validationObject={{
                required: formatMessage({ id: 'shared.validation.required' }),
                pattern: {
                  value: EmailRegExp,
                  required: formatMessage({
                    id: 'shared.validation.email.format',
                  }),
                },
              }}
            />

            <PasswordInput
              name="password"
              label={formatMessage({ id: 'shared.input.password.label' })}
              placeholder={formatMessage({
                id: 'shared.input.password.placeholder',
              })}
              validationObject={{
                required: formatMessage({ id: 'shared.validation.required' }),
                minLength: {
                  value: 8,
                  message: formatMessage({
                    id: 'shared.validation.password.length',
                  }),
                },
                validate: (value: string) =>
                  PasswordRegExp.test(value) ||
                  formatMessage({ id: 'shared.validation.password.length' }),
              }}
              showPasswordScore
            />

            <PasswordRepeatInput
              name="repeatPassword"
              label={formatMessage({
                id: 'registration.input.repeat-password.label',
              })}
              placeholder={formatMessage({
                id: 'shared.input.password.placeholder',
              })}
              followName="password"
            />

            <Checkbox
              name="subscription"
              text={
                <p>
                  <FormattedMessage id="registration.checkbox.newsletter.content" />{' '}
                  <Link to="/pravila-privatnosti">
                    <FormattedMessage id="shared.privacy-policy" />
                  </Link>
                  .
                </p>
              }
              defaultChecked={false}
              wrapperClassName={auth.consent}
            />

            <Checkbox
              name="termsAccepted"
              text={
                <p>
                  <FormattedMessage id="registration.checkbox.terms-accepted.content" />{' '}
                  <Link to="/pravila-privatnosti">
                    <FormattedMessage id="shared.privacy-policy" />
                  </Link>
                  . *
                </p>
              }
              defaultChecked={true}
              wrapperClassName={auth.consent}
            />

            {error && (
              <Alert
                type="error"
                text={typeof error === 'string' ? error : error.message}
              />
            )}

            <FormSubmit
              text={formatMessage({ id: 'registration.form.submit' })}
              stateIsChanging={authStateChanging}
              className={auth.registerBtn}
              disabled={!methods.watch('termsAccepted')}
            />
          </form>
        </FormProvider>

        <p css={auth.smallConsent}>
          <FormattedMessage id="registration.form.newsletter.content" />
        </p>
      </div>

      <p css={auth.copy}>
        &copy; {new Date().getFullYear()}{' '}
        <FormattedMessage id="shared.copyright" />
      </p>
    </Container>
  );

  async function onSubmit(data: RegistrationForm) {
    const resp = await dispatch(AuthThunks.register(data));

    if (resp !== 'Korisnik s datom email adresom već postoji') {
      if (data.termsAccepted) {
        sendContactInteraction({
          uid: resp,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          isSubscribed: data.subscription,
        }).then(() => {
          sendRegistrationInteraction(resp);
        });
      }

      navigateTo('/prijava', {
        state: {
          email: data.email,
        },
      });
    }
  }
};

export default Register;
